// Показывает/скрывает попапы с информацией. 'Стоимость', 'Как добраться' и тд.

import {classChangeSmooth} from '../utils/class-change-smooth';

export const infoToggle = () => {
  const infoButtons = document.querySelectorAll('.info-button');
  const infoContainers = document.querySelectorAll('.info-button-content');

  if (infoButtons && infoContainers) {
    const closePopup = () => {
      infoContainers.forEach((container) => {
        if (container.classList.contains('info-button-content--active')) {
          classChangeSmooth.remove(container, 'info-button-content--active');
        }
      });
    };

    const escPressHandler = (evt) => {
      if (evt.key === 'Escape') {
        closePopup();
      }
    };

    const clickHandler = (evt) => {
      if (!evt.target.closest('.info-button-content--active') && !evt.target.closest('.info-button')) {
        closePopup();
      }
    };

    const infoShowHide = (button, i) => {
      button.addEventListener('click', () => {
        if (infoContainers[i].classList.contains('info-button-content--active')) {
          classChangeSmooth.remove(infoContainers[i], 'info-button-content--active');
          return;
        }

        classChangeSmooth.add(infoContainers[i], 'info-button-content--active');
        document.addEventListener('keydown', escPressHandler);
        document.addEventListener('click', clickHandler);
      });
    };

    infoButtons.forEach((button, i) => {
      infoShowHide(button, i);
    });
  }
};
