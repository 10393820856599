// Таймер до повторной отправки смс в личном кабинете.

export const timer = (timerBlock, callBack = false) => {
  let seconds = 59;

  const timerFunction = () => {
    seconds--;
    if (seconds < 10) {
      timerBlock.textContent = '00:0' + seconds;
    } else {
      timerBlock.textContent = '00:' + seconds;
    }

    if (seconds === 0) {
      clearInterval(interval);
      timerBlock.textContent = '00:59';
      if (callBack) {
        callBack();
      }
    }
  };

  const interval = setInterval(timerFunction, 1000);
};
