// Для валидации форм.

import IMask from 'imask';

export const addListners = (inputs) => {
  inputs.forEach((input) => {
    input.addEventListener('input', () => {
      if (input.closest('.input-field').classList.contains('input-field--error')) {
        input.closest('.input-field').classList.remove('input-field--error');
      }
    });
  });
};

export const checkInputs = (inputsCheck) => {
  let errors = [];
  inputsCheck.forEach((input) => {
    if (input.value.length === 0) {
      input.closest('.input-field').classList.add('input-field--error');
      errors.push(input);
    }
  });

  if (errors.length !== 0) {
    errors[0].focus();
    return false;
  }

  return true;
};

export const mask = (inputTelNumber) => {
  IMask(inputTelNumber, {
    mask: '+{7}(000)000-00-00',
    lazy: false,
    placeholderChar: '  ',
  });
  inputTelNumber.focus();
};
