// слайдер в карточках
const MAXIMUM_TABLET_WIDTH = 1023;
const sliderCardElementList = document.querySelectorAll('.js-slider-card');

export const sliderCard = () => {

  const initSliderCard = (cardElement) => {
    const grids = cardElement.querySelectorAll('.js-slider-card-grid div');
    const sliderControlLongList = cardElement.querySelectorAll('.js-slider-control-long .slider-control-long__item');
    const sliderImageItemList = cardElement.querySelectorAll('.slider-card__image-item');

    const deleteClassActiveSliderControlLong = () => {
      sliderControlLongList.forEach((slide) => {
        slide.classList.remove('slider-control-long__item--active');
      });
    };

    const deleteClassActiveImageItemList = () => {
      sliderImageItemList.forEach((image) => {
        image.classList.remove('slider-card__image-item--active');
      });
    };

    // При ховере, активный класс добавляется / удаляется
    grids.forEach((grid, index) => {
      grid.addEventListener('mouseover', () => {
        if(sliderControlLongList[index]) {
          deleteClassActiveSliderControlLong();
          sliderControlLongList[index].classList.add('slider-control-long__item--active');
        }
        if(sliderImageItemList[index]) {
          deleteClassActiveImageItemList();
          sliderImageItemList[index].classList.add('slider-card__image-item--active');
        }
      });
    });
  };

  if (sliderCardElementList) {
    const isWindowSizeDesktop = () => window.innerWidth > MAXIMUM_TABLET_WIDTH;

    window.addEventListener('DOMContentLoaded', () => {
      if (isWindowSizeDesktop()) {
        // слайдер на все карточки
        sliderCardElementList.forEach((card) => {
          initSliderCard(card);
        });
      }
    });
  }
};
