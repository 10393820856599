// обновление счетчика на мобильной версии слайдера с тремя карточками
export const roomCounter = () => {
  const room = document.querySelector('.js-room');
  if (room) {
    const roomCounterElement = room.querySelector('.js-room-counter');
    if (!roomCounterElement) return;
    const sliderPaginationItem = room.querySelectorAll('.js-slider-pagination-item');

    const getAllCountPaginationItem = () => {
      return sliderPaginationItem.length;
    };

    const getActivePaginationNumber = () => {
      const sliderPaginationItemElement = room.querySelectorAll('.js-slider-pagination-item');
      let result = -1;
      sliderPaginationItemElement.forEach((item, index) => {
        if (item.classList.contains('js-slider-pagination-item-active')) {
          result = index;
        }
      });
      return result;
    };

    const updateRoomCounter = () => {
      roomCounterElement.textContent = `${getActivePaginationNumber() + 1} / ${getAllCountPaginationItem()}`;
    };

    updateRoomCounter();
  }
};
