// слайдер на странице выбора кабинета

import Swiper from 'swiper/bundle';

export const initCabientSlider = () => {
  const cabientSlider = document.querySelector('.js-cabinet-slider');
  const slider = () => {
    return new Swiper(cabientSlider, {
      slidesPerView: 1,
      spaceBetween: 8,
      uniqueNavElements: false,
      pagination: {
        el: '.slider__controls',
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 3,
        renderBullet(index, className) {
          return `<button class="slider-pagination__item-bullet ${className}" tabindex="1"></button>`;
        },
      },
      breakpoints: {
        768: {
          spaceBetween: 0,
        },
      },
      navigation: {
        nextEl: '.js-movement-button-forward',
        prevEl: '.js-movement-button-back',
        disabledClass: 'movement-button--disabled',
      },
      a11y: {
        enabled: true,
        prevSlideMessage: 'прошлое фото',
        nextSlideMessage: 'следующее фото',
        firstSlideMessage: 'первое фото',
        lastSlideMessage: 'последнее фото',
        containerMessage: 'фотографии кабинета',
        paginationBulletMessage: 'перейти к фото №{{index}}',
      },
    });
  };

  if (cabientSlider) {
    slider();
  }
};
