// аккордеон

export const initAccordion = () => {
  const accordions = document.querySelectorAll('.accordion');
  if (accordions) {
    let currentAccordion = null;
    let accordionContentHeight = null;

    const closeAccordions = (accordionList, evt) => {
      accordionList.forEach((accordion) => {
        let accordionIcon = accordion.querySelector('svg');
        let accordionWrapper = accordion.querySelector('.accordion__wrapper');

        // для аккордеона в хедере
        if (accordionWrapper.classList.contains('opacity')) {
          accordionWrapper.classList.remove('opacity');
          setTimeout(() => {
            accordionWrapper.classList.remove('opacity-0');
          }, 300);
        }

        if (accordion.classList.contains('accordion__item--active')) {
          accordionIcon.style.transform = null;

          if (evt.target.closest('.accordion--header') && document.body.clientWidth < 1024) {
            accordion.classList.remove('accordion__item--active');
          } else {
            setTimeout(() => {
              accordion.classList.remove('accordion__item--active');
            }, 300);
          }
          accordionWrapper.style.maxHeight = null;
        }
      });
    };

    accordions.forEach((accordion) => {
      accordion.addEventListener('click', (evt) => {
        const accordionItem = evt.target.closest('.accordion__item');
        const accordionItems = accordion.querySelectorAll('.accordion__item');
        const accordionTitle = accordionItem.querySelector('.accordion__button');
        const accordionIcon = accordionTitle.querySelector('.accordion__button svg');
        const accordionWrapper = accordionItem.querySelector('.accordion__wrapper');
        accordionContentHeight = accordionItem.querySelector('.accordion__content').offsetHeight;

        const closeAccordeonHeader = () => {
          accordionWrapper.classList.remove('opacity');
          accordionIcon.style.transform = null;
          setTimeout(() => {
            accordionWrapper.classList.remove('opacity-0');
            accordionItem.classList.remove('accordion__item--active');
          }, 300);
          window.removeEventListener('keydown', escPressHandler);
          window.removeEventListener('keydown', clickHandler);
          currentAccordion = null;
        };

        const escPressHandler = (evt) => {
          if (evt.key === 'Escape') {
            closeAccordeonHeader();
          }
        };

        const clickHandler = (evt) => {
          if (!evt.target.closest('.main-nav__list--inner') && !evt.target.closest('.accordion__button')) {
            closeAccordeonHeader();
          }
        };

        if (evt.target === accordionTitle || evt.target.closest('.accordion__button')) {
          if (currentAccordion) {
            closeAccordions(accordionItems, evt);
          }

          if (accordionItem === currentAccordion) {
            currentAccordion = null;
            closeAccordions(accordionItems, evt);
            return;
          }

          // для аккордеона в хедере
          if (evt.target.closest('.accordion--header') && document.body.clientWidth > 1023) {
            window.addEventListener('keydown', escPressHandler);
            window.addEventListener('click', clickHandler);
            accordionWrapper.classList.add('opacity-0');
            accordionItem.classList.add('accordion__item--active');
            accordionIcon.style.transform = 'rotate(180deg)';
            setTimeout(() => {
              accordionWrapper.classList.add('opacity');
            }, 10);
            currentAccordion = accordionItem;
            return;
          }

          if (evt.target.closest('.accordion__item--active')) {
            return;
          }

          accordionItem.classList.add('accordion__item--active');
          currentAccordion = accordionItem;
          accordionWrapper.style.maxHeight = accordionContentHeight + 'px';
          accordionIcon.style.transform = 'rotate(180deg)';
        }

        window.addEventListener('resize', () => {
          if (currentAccordion) {
            let currentAccordionWrapper = currentAccordion.querySelector('.accordion__wrapper');
            let currentAccordionHeight = currentAccordion.querySelector('.accordion__content').offsetHeight;
            currentAccordionWrapper.style.maxHeight = currentAccordionHeight + 'px';
          }
        });
      });
    });

    // для аккордеона в хедере
    const headerAccordion = document.querySelector('.accordion--header');

    if (headerAccordion) {
      const mobileMenuButton = document.querySelector('.header__nav-toggle');
      const headerAccordionItems = headerAccordion.querySelectorAll('.accordion__item');
      mobileMenuButton.addEventListener('click', (evt) => {
        closeAccordions(headerAccordionItems, evt);
        currentAccordion = null;
      });
    }
  }
};
