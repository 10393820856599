// Обновление позиции уведомления, если необходимо показать несколько сразу
export const updatePosition = () => {
  const activeModals = document.querySelectorAll('.modal-feedback--active');
  const modalHeight = 64;

  if (activeModals) {
    activeModals.forEach((activeModal, index) => {
      activeModal.style.bottom = index * modalHeight + 'px';
    });
  }
};
