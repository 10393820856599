// Проверяет совпадение паролей.

export const passwordCompare = (passInputsArray) => {
  if (passInputsArray.length > 1) {
    let [input1, input2] = passInputsArray;

    if (input1.value === input2.value) {
      return true;
    } else {
      return false;
    }
  }
  if (passInputsArray.length === 1) {
    return true;
  }
};
