// Плавно скрывает/показывает блоки.

export const classChangeSmooth = {
  add: (element, className) => {
    element.classList.add('opacity-0');
    element.classList.add(className);
    setTimeout(() => {
      element.classList.add('opacity');
    }, 10);
  },

  remove: (element, className) => {
    element.classList.remove('opacity');
    setTimeout(() => {
      element.classList.remove('opacity-0');
      element.classList.remove(className);
    }, 300);
  },
};
