// Для шапки сайта в зависимости от разрешения

export const headerMenu = () => {
  const header = document.querySelector('.header');

  if (header) {
    let isMenuRendered = false;
    const navButton = header.querySelector('.header__nav-toggle');
    const menu = header.querySelector('.header__nav-container');
    const menuCloseButton = header.querySelector('.header__nav-close-button');
    const overlay = document.querySelector('.overlay');

    const closeMenu = () => {
      isMenuRendered = false;
      menu.classList.remove('opacity');
      document.body.classList.remove('scroll-lock');
      overlay.classList.remove('overlay--show');
      setTimeout(() => {
        menu.classList.remove('header__nav-container--active');
      }, 300);
      menuCloseButton.removeEventListener('click', closeMenu);
      overlay.removeEventListener('click', closeMenu);
      window.removeEventListener('keydown', escPressHandler);
      window.removeEventListener('resize', resizeHandler);
    };

    const escPressHandler = (evt) => {
      if (evt.key === 'Escape') {
        closeMenu();
      }
    };

    const resizeHandler = () => {
      if (isMenuRendered && document.body.clientWidth > 1023) {
        document.body.classList.remove('scroll-lock');
        overlay.classList.remove('overlay--show');
      }

      if (isMenuRendered && document.body.clientWidth < 1024) {
        document.body.classList.add('scroll-lock');
        overlay.classList.add('overlay--show');
      }
    };

    navButton.addEventListener('click', () => {
      isMenuRendered = true;
      menu.classList.add('header__nav-container--active');
      document.body.classList.add('scroll-lock');
      overlay.classList.add('overlay--show');
      setTimeout(() => {
        menu.classList.add('opacity');
      }, 10);
      menuCloseButton.addEventListener('click', closeMenu);
      overlay.addEventListener('click', closeMenu);
      window.addEventListener('keydown', escPressHandler);
      window.addEventListener('resize', resizeHandler);
    });
  }
};
