import Vue from 'vue';
import App from '../components/Calendar/Calendar.vue';
import smoothscroll from 'smoothscroll-polyfill';
import {iosVhFix} from './utils/ios-vh-fix';
import {headerMenu} from './modules/header';
import {initAccordion} from './modules/accordion';
import {changeData} from './modules/change-data';
import {balance} from './modules/balance';
import {initCabientSlider} from './sliders/init-cabinet';
import {initCabinetPreviewTabs} from './sliders/init-cabinet-preview-tabs';
import {initThreeCardsSlider} from './sliders/init-three-cards-slider';
import {initReviews} from './modules/init-layout';
import {tabs} from './modules/tabs';
import {personalArea} from './modules/personal-area';
import {infoToggle} from './modules/info-toggle';
import {sliderCard} from './modules/slider-card';
import {updatePosition} from './modules/update-modal-position';
import {passwordVisibility} from './modules/password-visibility';
import {registrationFormValidation} from './modules/registration-form-validation';
import {roomCounter} from './modules/room-counter';

// init calendar
document.addEventListener('DOMContentLoaded', () => {
  const div = document.getElementById('app-calendar')
  if(div) {
    const el = div.appendChild(document.createElement('application'))

    const app = new Vue({
      el,
      render: h => h(App)
    })
  }
})
// init calendar

// init other functions
iosVhFix();
smoothscroll.polyfill();
headerMenu();
initAccordion()

changeData();
balance();
initCabientSlider();
initCabinetPreviewTabs();
initReviews();
tabs();
personalArea();
infoToggle();
initThreeCardsSlider();
sliderCard();
updatePosition();
passwordVisibility();
registrationFormValidation();
roomCounter();
// init other functions
