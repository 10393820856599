// Изменение данных пользователя в Настройках
import IMask from 'imask';

export const changeData = () => {
  const dataBlocks = document.querySelectorAll('.data');
  const dataHeader = document.querySelector('.profile-header h2');

  if (dataBlocks) {
    dataBlocks.forEach((dataBlock) => {
      const changeDataButton = dataBlock.querySelector('.data__button-change');
      const dataField = dataBlock.querySelector('.data__field');
      const dataInputField = dataBlock.querySelector('.data__field input');
      const dataWrapper = dataBlock.querySelector('.data__wrapper');
      const saveButton = dataBlock.querySelector('.data__button-save');
      const resetButton = dataBlock.querySelector('.data__button-reset');

      if (dataInputField.type === 'tel') {
        (() => new IMask(dataInputField, {
          mask: '+{7} (000) 000 - 00 - 00',
        }))();
      }

      const toggleChangeDataForm = () => {
        dataWrapper.classList.toggle('data__wrapper--active');
        dataInputField.toggleAttribute('readonly');
      };

      changeDataButton.addEventListener('click', () => {
        toggleChangeDataForm();
      });

      const removeErrorMessage = () => {
        if (dataField.classList.contains('input-field--error')) {
          dataField.classList.remove('input-field--error');
        }
      };

      saveButton.addEventListener('click', (evt) => {
        evt.preventDefault();

        if (dataInputField.value !== '') {
          dataInputField.placeholder = dataInputField.value;

          if (dataHeader && dataBlock.classList.contains('personal-info')) {
            dataHeader.innerText = dataInputField.value;
          }

          dataInputField.value = '';
          removeErrorMessage();
          toggleChangeDataForm();
        } else {
          dataField.classList.add('input-field--error');
        }
      });

      resetButton.addEventListener('click', () => {
        removeErrorMessage();
        toggleChangeDataForm();
      });
    });
  }
};
