
// Модальное окно Лк: личный кабинет (баланс, мои бронирования итд...)
export const personalArea = () => {
  const navLogged = document.querySelector('.header__nav-login--logged');
  if (navLogged) {
    const closeButton = document.querySelector('.js-personal-area-close-button');
    const overlay = document.querySelector('.overlay');
    const body = document.querySelector('body');
    const personalAreaMenu = document.querySelector('.js-personal-area');

    const changePageScrollLock = () => {
      body.classList.toggle('scroll-lock');
    };

    const changePageOverlay = () => {
      overlay.classList.toggle('overlay--show');
    };

    const isPageScrollLock = () => {
      return body.classList.contains('scroll-lock');
    };

    const isOverlayShow = () => {
      return overlay.classList.contains('overlay--show');
    };

    const isPersonalAreaShow = () => {
      let result = null;
      if (personalAreaMenu) {
        result = personalAreaMenu.classList.contains('personal-area--active');
      }
      return result;
    };

    const changePersonalAreaShow = () => {
      if (personalAreaMenu) {
        personalAreaMenu.classList.toggle('personal-area--active');
      }
    };

    const showPageOverlay = () => {
      overlay.classList.add('overlay--z-index');
    };

    const hiddenPageOverlay = () => {
      overlay.classList.remove('overlay--z-index');
    };

    const showPersonalArea = () => {
      if (!isPageScrollLock()) {
        changePageScrollLock();
      }

      if (!isOverlayShow()) {
        changePageOverlay();
        showPageOverlay();
      }

      if (!isPersonalAreaShow()) {
        changePersonalAreaShow();
      }
    };

    const closePersonalArea = () => {
      if (isPageScrollLock()) {
        changePageScrollLock();
      }

      if (isOverlayShow()) {
        changePageOverlay();
        hiddenPageOverlay();
      }

      if (isPersonalAreaShow()) {
        changePersonalAreaShow();
      }
    };

    navLogged.addEventListener('click', (evt) => {
      evt.preventDefault();
      showPersonalArea();
      window.addEventListener('keydown', escPressHandler);
      window.addEventListener('click', clickHandler, true);
    });

    if (closeButton) {
      closeButton.addEventListener('click', (evt) => {
        evt.preventDefault();
        closePersonalArea();
      });
    }

    const clickHandler = (evt) => {
      if (evt.target === overlay) {
        closePersonalArea();
        window.removeEventListener('click', clickHandler);
      }
    };

    const escPressHandler = (evt) => {
      if (evt.key === 'Escape') {
        closePersonalArea();
        window.removeEventListener('keydown', escPressHandler);
      }
    };
  }
};
