// Показывает/скрывает видимость пароля при клике на иконку 'глаза' в инпуте ввода пароля.

export const passwordVisibility = () => {
  const visibilityButtons = document.querySelectorAll('.js-password-visibility');

  if (visibilityButtons) {
    visibilityButtons.forEach((visibilityButton) => {
      const buttonVisibility = visibilityButton.querySelector('button');
      const passInput = visibilityButton.querySelector('input');
      const icons = visibilityButton.querySelectorAll('.input-field__icon');

      buttonVisibility.addEventListener('click', () => {
        if (passInput.getAttribute('type') === 'password') {
          visibilityButton.classList.add('password-visibility--show');
          passInput.setAttribute('type', 'text');
        } else {
          visibilityButton.classList.remove('password-visibility--show');
          passInput.setAttribute('type', 'password');
        }

        icons.forEach((icon) => {
          icon.classList.toggle('input-field__icon--show');
        });
      });
    });
  }
};
