// Пополенение баланса
import AutoNumeric from 'autonumeric';

export const balance = () => {
  const balanceBlock = document.querySelector('.balance');

  if (balanceBlock) {
    const inputField = balanceBlock.querySelector('input');
    const submitButton = balanceBlock.querySelector('button');
    const inputBlock = balanceBlock.querySelector('.input-field');
    (() => new AutoNumeric(inputField, {
      currencySymbol: ' ₽',
      currencySymbolPlacement: 's',
      digitGroupSeparator: ' ',
      alwaysAllowDecimalCharacter: false,
      decimalPlaces: 0,
    }))();

    submitButton.addEventListener('click', (evt) => {
      const value = inputField.value
      if (value === '' || value.indexOf('-') === 0 || value === '0 ₽' || value === ' ₽' ) {
        evt.preventDefault();
        inputBlock.classList.add('input-field--error');
      }
    });
  }
};
