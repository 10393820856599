// Переключение табов
export const tabs = () => {
  const tabsBlock = document.querySelector('.tabs');
  const cards = document.querySelectorAll('.tabs__card');
  let cardActive = document.querySelector('.tabs__card--active');

  if (tabsBlock) {
    const tabLinks = tabsBlock.querySelectorAll('.tabs__nav .link');
    let tabLinkActive = tabsBlock.querySelector('.link--active');

    const toggleTabs = () => {
      tabLinks.forEach((link, index) => {
        if (link.classList.contains('link--active')) {
          tabLinkActive = link;
        }

        link.addEventListener('click', (evt) => {
          evt.preventDefault();

          tabLinkActive.classList.remove('link--active');
          tabLinkActive = link;
          link.classList.add('link--active');

          cards.forEach((card, cardIndex) => {
            if (card.classList.contains('tabs__card--active')) {
              cardActive = card;
            }

            if (index === cardIndex) {
              cardActive.classList.remove('tabs__card--active');
              cardActive = card;
              card.classList.add('tabs__card--active');
            }
          });
        });
      });
    };

    toggleTabs();
  }
};
