// Показывает уведомление.

export const showAlert = (alert) => {
  let renderedAlerts = document.querySelectorAll('.modal-feedback');
  setTimeout(()=> {
    alert.classList.add('modal-feedback--active');
    alert.style.bottom = (renderedAlerts.length - 1) * renderedAlerts[0].offsetHeight + 'px';
  }, 10);
  setTimeout(()=> {
    alert.remove();
    alert = null;
  }, 3000);
};
