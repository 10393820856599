// Сетка отзывов
import Masonry from 'masonry-layout';

const reviewsList = document.querySelector('.reviews-list__list');

export const initReviews = () => {
  const reviewsLayout = () => {
    return new Masonry(reviewsList, {
      itemSelector: '.review',
      columnWidth: '.review',
      gutter: 30,
      percentPosition: true,
      horizontalOrder: true,
    });
  };

  if (reviewsList) {
    const reviews = reviewsList.querySelectorAll('.review');
    const upperElem = reviews[reviews.length - 3];
    if (upperElem) {
      const body = document.querySelector('body');
      const windowWidth = body.clientWidth;

      if (reviews.length >= 3) {
        reviewsList.classList.remove('reviews-list__list--one-row');
        reviewsLayout();

        const reviewTop = Math.abs((upperElem.getBoundingClientRect().bottom + 30) - reviewsList.getBoundingClientRect().top);

        // Условие для центрирования последнего отзыва, если он находится один в ряду

        if (reviews.length % 3 === 1 && windowWidth > 1176) {
          reviews[reviews.length - 1].classList.add('review--center');
          reviews[reviews.length - 1].style.top = reviewTop + 'px';
          reviewsList.style.height = reviewTop + 32 + reviews[reviews.length - 1].getBoundingClientRect().height + 'px';
        }
      }
    }
  }
};
