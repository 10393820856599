// слайдер на главной страницы (3 карточки на десктопной версии)

import {roomCounter} from '../modules/room-counter';
import Swiper from 'swiper/bundle';

const firstLocationSlider = document.querySelector('.root-location-slider1');
const secondLocationSlider = document.querySelector('.root-location-slider2');

export const initThreeCardsSlider = () => {
  const slider = (locationSlider, locationId) => {
    const sliderWrap = locationSlider.querySelector('.js-slider-wrap-list');
    const buttonNext = document.querySelector('.js-room-slider-button-next'+locationId);
    const buttonBack = document.querySelector('.js-room-slider-button-back'+locationId);

    const swiper = new Swiper(sliderWrap, {
      slidesPerView: 'auto',
      spaceBetween: 8,
      loop: false,
      uniqueNavElements: false,
      a11y: {
        enabled: true,
        prevSlideMessage: 'прошлое фото',
        nextSlideMessage: 'следующее фото',
        firstSlideMessage: 'первое фото',
        lastSlideMessage: 'последнее фото',
        containerMessage: 'фотографии кабинета',
        paginationBulletMessage: 'перейти к фото №{{index}}',
      },
      pagination: {
        bulletElement: 'button',
        el: '.slider__pagination',
        bulletClass: 'slider-pagination__item js-slider-pagination-item',
        bulletActiveClass: 'slider-pagination__item--active js-slider-pagination-item-active',
        clickable: true,
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: buttonNext,
        prevEl: buttonBack,
      },
    });

    swiper.on('slideChange', () => {
      roomCounter();
    });

    return swiper;
  };

  if (firstLocationSlider) {
    slider(firstLocationSlider, 1);
  }

  if (secondLocationSlider) {
    slider(secondLocationSlider, 2)
  }
};
