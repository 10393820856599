// Валидация формы личного кабиента.

import {addListners, checkInputs, mask} from '../utils/validation';
import {passwordCompare} from './password-compare';
import {showAlert} from '../utils/show-alert';
import {notificationTemplate} from '../templates/notification';
import {timer} from './timer';

export const registrationFormValidation = () => {
  const registrationForm = document.querySelector('.js-registration-form');

  if (registrationForm) {
    const passwordInputs = registrationForm.querySelectorAll('input[type="password"]');
    const inputs = registrationForm.querySelectorAll('.js-text');
    const checkBox = registrationForm.querySelector('.checkbox input[type="checkbox"]');
    const inputTelNumber = registrationForm.querySelector('input[id="tel"]');
    const countDown = registrationForm.querySelector('.js-timer');
    const hiddenBlocks = registrationForm.querySelectorAll('.lk-form__hide');
    const newSmsButton = registrationForm.querySelector('.js-new-sms');
    let isListnerAdded = false;

    const showHiddenBlock = () => {
      hiddenBlocks.forEach((hiddenBlock) => {
        hiddenBlock.classList.toggle('lk-form__hide--hidden');
      });
    };

    const timerStart = () => {
      timer(countDown, showHiddenBlock);
    };

    if (countDown) {
      timerStart();
    }

    if (newSmsButton) {
      newSmsButton.addEventListener('click', showHiddenBlock);
      newSmsButton.addEventListener('click', timerStart);
    }


    if (inputTelNumber) {
      mask(inputTelNumber);
    }

    registrationForm.addEventListener('submit', (evt) => {
      if (passwordInputs.length !== 0 && !passwordCompare(passwordInputs) && checkInputs(inputs)) {
        evt.preventDefault();
        passwordInputs[0].focus();
        registrationForm.insertAdjacentHTML('afterend', notificationTemplate('modal-feedback--error',
            'Пароли не совпадают'));
        let alert = registrationForm.nextElementSibling;
        showAlert(alert);
      }

      if (inputs.length !== 0 && !checkInputs(inputs)) {
        evt.preventDefault();
        if (!isListnerAdded) {
          addListners(inputs);
          isListnerAdded = true;
        }
      }

      if (checkBox && !checkBox.checked) {
        evt.preventDefault();
        registrationForm.insertAdjacentHTML('afterend', notificationTemplate('modal-feedback--error',
            'Необходимо дать согласие на обработку персональных данных'));
        let alert = registrationForm.nextElementSibling;
        showAlert(alert);
      }

      if (inputTelNumber && inputTelNumber.value.length !== 16) {
        evt.preventDefault();
        inputTelNumber.closest('.input-field').classList.add('input-field--error');
        if (!isListnerAdded) {
          addListners(inputs);
          isListnerAdded = true;
        }
      }
    });
  }
};
