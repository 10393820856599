<template>
  <div class="page-modal js-calendar-modal opacity-0 page-modal--active opacity">
    <div class="page-modal__container page-modal__container--top">
      <button class="page-modal__close-button link link--white" aria-label="Закрыть меню" type="button" @click="closeSignInForm">
        <svg width="32" height="32"><use xlink:href="#arrow-right"></use></svg>
      </button>
      <h3 class="title title--size-small-fixed">Для бронирования необходимо войти</h3>
    </div>
    <form action="/login" method="post" class="page-modal__container page-modal__container--bottom js-modal-form">
      <div class="input-field">
        <label for="tel">Телефон</label>
        <input type="tel" name="tel" id="tel" placeholder="+7(000)000-00-00" value="+7" class="js-text">
        <span class="input-field__error-msg">введите корректный номер телефона</span>
      </div>
      <div class="input-field js-password-visibility">
        <button type="button" aria-label="Показать пароль">
          <svg class="input-field__icon input-field__icon--show" width="20" height="20"><use xlink:href="#icon-eye-closed"></use></svg>
          <svg class="input-field__icon" width="20" height="20"><use xlink:href="#icon-eye-opened"></use></svg>
        </button>
        <label for="password">Пароль</label>
        <input type="password" name="password" id="password" placeholder="password" class="js-text">
        <span class="input-field__error-msg">заполните это поле</span>
      </div>
      <button class="button page-modal__button" type="submit">
        <svg width="24" height="24"><use xlink:href="#log-in-dark"></use></svg>
        Войти</button>
      <p class="page-modal__text">Забыли пароль? <a class="color-purple" href="/clients/recover">Получите новый</a></p>
      <br>
      <p class="page-modal__text">Нет аккаунта? <a class="color-purple" href="/signup">Зарегистрируйтесь</a></p>
    </form>
  </div>
</template>

<script>
export default {
  name: "SignInForm",
  props: {
    closeSignInForm: {
      type: Function,
      required: true,
    },
  }
}
</script>

<style scoped>

</style>
