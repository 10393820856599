// слайдеры в табах фото кабинета на странице "локация"

import Swiper from 'swiper/bundle';

export const initCabinetPreviewTabs = () => {
  const MOBILE_MIN_WIDTH = '(min-width:1024px)';
  const locationSliders = document.querySelectorAll('.rent-cabinet__slider');
  let locationSwipers = [];

  const slider = () => {
    locationSliders.forEach((locationSlider, i) => {
      locationSwipers[i] = new Swiper(locationSlider, {
        slidesPerView: 1,
        spaceBetween: 8,
        loop: true,
        uniqueNavElements: false,
        resizeObserver: true,
        pagination: {
          el: '.control' + (i + 1),
          bulletClass: 'slider-pagination__item',
          bulletActiveClass: 'slider-pagination__item--active',
        },
        breakpoints: {
          666: {
            slidesPerView: 2,
          },
        },
        a11y: {
          enabled: true,
          prevSlideMessage: 'прошлое фото',
          nextSlideMessage: 'следующее фото',
          firstSlideMessage: 'первое фото',
          lastSlideMessage: 'последнее фото',
          containerMessage: 'фотографии кабинета',
        },
      });
    });
  };

  if (locationSliders && document.body.clientWidth < 1023) {
    slider();
  }

  window.matchMedia(MOBILE_MIN_WIDTH).addListener((evt) => {
    locationSwipers.forEach((swiper) => {
      if (evt.matches && swiper) {
        swiper.destroy(true, true);
        locationSwipers = [];
        return;
      }
    });

    if (!evt.matches) {
      slider();
    }
  });

};
